@import "~react-awesome-slider/dist/styles.css";

h2 {
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.form-select {
  border: 1px solid #15558d;
  border-radius: 5px;
  color: #15558d;
  background: #fff;
  width: 100%;
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-inner {
  overflow-y: auto;
    max-height: 80vh; /* this limits the height to 80% of the viewport's height */
  padding: 20px;
  background: white;
  border-radius: 5px;
  max-width: 90%;
  width: 500px;
}

.tag {
  background-color:#15558d;
  color: #fff;
  padding: 8px;
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: calc(25% - 0.5rem);
  border: 0;
}

.awssld__content {
  background-color: #2f2f2f00;
}
.tag:hover {
  background-color: #0de0fe;
}

.tag-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

@media screen and (max-width: 768px) {
  .tag-container {
    flex-direction: column;
    align-items: center;
  }

  h2 {
    font-size: 24px;
  }

  .form-select {
    width: 100%;
  }
}

.aws-container, .aws-container * {
  background: transparent !important;
}

.aws-btn {
  background-color: transparent !important;
}

.aws-btn--prev, .aws-btn--next {
  color: #15558d !important; 
}

@media screen and (max-width: 600px) {
  h2 {
    font-size: 20px;
  }

  .tag-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .tag {
    margin-bottom: 10px;
  }

  .aws-btn--prev, .aws-btn--next {
    font-size: 16px;
  }
}
.aws-slider .aws-btn--wrapper, .aws-slider .aws-btn--wrapper .aws-btn  {
  background-color: transparent !important;
}

.aws-slider .aws-btn--prev, .aws-slider .aws-btn--next {
  color: #15558d !important;
}

.aws-slider .aws-slider__container, .aws-slider .aws-slide {
  background-color: transparent !important;
}
.template-container {
  position: relative;
  /* Make sure the container fits the image. If your image has a fixed height, you might set that height here. */
}

.template-btn {
  position: absolute;
  top: 50%;  /* This positions the button's top edge to the middle of the image */
  left: 50%;  /* This positions the button's left edge to the middle of the image */
  transform: translate(-50%, -50%); /* This shifts the button back by half of its own width and height, effectively centering it on the image */
  z-index: 2; /* To make sure the button stays on top */
}

.template-img {
  display: block;
  width: 100%;
  height: auto;
}
.close-btn, .save-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.close-btn:hover, .save-btn:hover {
  transform: scale(1.05);
}

.close-btn {
  background-color: #f44336; /* Red */
  color: white;
  margin-right: 10px;
}

.close-btn:hover {
  background-color: #d32f2f; /* Darker Red */
}

.save-btn {
  background-color: #15558d; /* Green */
  color: white;
}

.save-btn:hover {
  background-color: #38628e; /* Darker Green */
}

.popupedit {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-inner1 {
  width: 80%;
  max-width: 500px;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.remove-btn {
  background-color: #f44336;
  border: none;
 
  color: #fff;
  font-size: 12px;
  cursor: pointer;
  margin-left: 10px;
  padding: 5px 8px;
}

.remove-btn:hover {
  background-color: darkred;
}


.time-slot-edit-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.time-slot-edit-item {
  flex: 1;
  padding: 10px;
}


.save-btn {
  margin-top: 20px;
  align-self: flex-start;
}


img {
  max-width: 100%; /* This will ensure the image takes the maximum width of its container but not more */
  height: auto; /* This will ensure the height adjusts automatically while maintaining the aspect ratio */
}
/* ./style.css */

.img-responsive {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.img-shadow {
  box-shadow: 0px 4px 8px rgba(244, 244, 244, 0.1);
  border-radius: 8px;
}

.img-container {
  background-color: #f9f9f9;
  padding: 20px;
  text-align: center;
  
}

.img-caption {
  margin-top: 10px;
  font-style: italic;
}

.img-responsive:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease-in-out;
}
.custom-button {
  background-color: transparent;  /* A shade of blue */
  color: rgb(11, 11, 11);               /* Text color */
  padding: 10px 20px;         /* Button padding for top-bottom and left-right */
  border: none;               /* Removes the default border */
  border-radius: 5px;         /* Rounded corners */
  font-size: 16px;            /* Font size */
  font-weight: 600;           /* Bold text */
  cursor: pointer;            /* Hand cursor on hover */
  transition: background-color 0.3s ease;  /* Smooth transition effect */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow to give it a bit of depth */
  text-decoration: none;     /* If using an anchor tag, this removes the underline */
}

.custom-button:hover {
  background-color: #fff;  /* A darker shade of blue on hover */
}