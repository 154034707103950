.loginstyle {
    float: right;
}

.navbar-home {
    background-color: #E1F3FFff !important;
}
.header-navbar-rht .dropdown-menu.show {
    right: 0;
    left: auto;
}
.Dr {
    width: 90%;
    margin-bottom: 0;
}
@media screen and (max-width: 600px) {
    .Dr {
        display: none;
    }
}


.booking-button {
    text-align: center;

}

h3 {
    font-weight: bolder;
    color: #15558d;
}

.body-home {
    background-color: #E1F3FFff;
    padding-top: 100px;
}
.make{
    width: 100%;
    color:#15558d;
    align-items: center;
    border-radius: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: bold;
    margin-bottom: 20px;
}
/* HomeHeader Styling */
.menu-option {
    color: black;
    font-weight: bold;
}