.timing-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.time-button-container {
 
  display: flex;
  overflow-x: auto;
  padding: 10px 0;
}

.time-button {
  width: 70px;
  margin: 0 5px;
  background-color: #4478a5;
}


.time-button.active {
  color: #fff;
  background-color: #15558d;
}

.form-label,
h1 {
  font-family: 'Roboto', sans-serif;
  color: #05054c;
  font-weight: bold;
}

.form-control {
  border: 1px solid #15558d;
  border-radius: 4px;
  padding: 5px;
  font-family: 'Roboto', sans-serif;
}
tr:hover {
  background: transparent !important;
}
@media screen and (max-width: 768px) {
  .time-button-container {
    flex-direction: column; /* Stack the time buttons vertically */
    overflow-x: visible; /* Allow the vertical overflow */
  }
}