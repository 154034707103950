.row-gap-3 {
    row-gap: 1rem;
}
.card {
    border: 1px solid #ededed !important;
}
.patientbox {
    background-color: white;
    padding: 20px;
    border: 1px solid #ededed;
    width: 100%;
    border-radius: .25rem;
}
  .patient{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 11px;
    border-bottom: 1px solid #ededed;
  }
  .patient img {
    max-width: 100%;
    border-radius: 50px;
    width: 100px; 
    height: 100px; 
  align-items: center;
  }
  .bolded{
    font-weight: bold;
  }
  .patient-info {
    display: flex;
    flex-direction: column;
}

.info-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}
.text-end{
    text-align: end;
}
.align-right {
    text-align: right;
}
.patientbox1{
    background-color: white;
    padding: 20px;
    border: 1px solid #ededed;
    border-radius: .25rem;
}
.booking{
    margin-bottom: 15px;
    border-top: 1px solid #ededed;
    margin-top: 10px;
    display: flex;
    align-items: center;
}
.booking img{
    max-width: 100%;
    border-radius: 50px;
    width: 80px; 
    height: 80px;
}
.booking-details {
    flex-grow: 1;
    padding-left: 30px;
}
.table-title {
    border-bottom: 5px solid #5DCAE3;
    margin-bottom: 1rem;
    padding: 1rem;
    text-align: center;
}
.table-title h3{
    padding: 0;
    margin: 0;
    border: 0;
    color: #5DCAE3;
}
.table-bordered td, .table-bordered th {
    border: 0 !important;
}

#status {
    width: fit-content;
    height: 25px;
    border-radius: 5px;
    margin: auto;
    display: block;
    padding: 0 0.25rem;
}

.confirmed, .btn-outline-primary.success {
    color: #537B35;
    background: #d9e9cf;
    border: 0;
}

.pending {
    color: #ECB731;
    background: #f3e9cd;
}

.canceled, .btn-outline-primary.danger {
    color: #ED1B2E;
    background: #f4d4d6;
    border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #fff !important;
}
.table-striped tbody tr {
    border-bottom: 1px solid #ededed;
}