.navbar {
  background-color: #fff;
}

.div-row {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
}

.div-row .div-col-md,
.div-row .div {
  box-sizing: border-box;
  flex: 1;
  padding: 0 20px;
  max-width: 50%;
}

.div-row .login-banner-png {
  max-width: 100%;
  height: auto;
}

.div-row .div {
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  flex-direction: column;
  gap: 20px;
  padding: 25px;
}

.div-row .div-login-header {
  gap: 2.59px;
  width: 100%;
}

.div-row .heading-forgot,
.div-row .text-wrapper {
  width: 100%;
  white-space: normal;
}

.div-row .button,
.div-row .button2 {
  justify-content: center;
  width: 100%;
  padding: 9px 0;
  text-align: center;
}

.div-row .text-wrapper-2,
.div-row .text-wrapper-3 {
  text-align: center;
  width: 100%;
  white-space: normal;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .div-row .div-col-md,
  .div-row .div {
    max-width: 100%;
  }
}