.header-container {
    background-color: white;

}

.imageview {
    width: 50px;
    border-radius: 50%;
    right: 0px;
    left: auto;
}

.header-navbar-rht .dropdown-toggle::after {
    display: none;
}