/*
Author       : HexaPi Tech
Project Name:  Tabiby - Doctor Finder
Version      : 0.0.1
*/

/*============================
 [Table of General CSS Across Project not for Components, Common between Patient,Doctors and Admin]

1. App General
2. Footer
3. Breadcrumb

========================================*/

/*-----------------
	1. App General
-----------------------*/

body{
  padding-top: 50px;
}
.content{
  background-color:#f8f9fa;
  min-height: 200px;
	padding: 30px 0 0;
}
#bg {
  background-color: #15558d!important;
}

.container-fluid {
	padding-left: 30px;
	padding-right: 30px;
}
@media only screen and (max-width: 1199px) {


	.container-fluid {
 
		padding-left: 20px;
		padding-right: 20px;
	}
}
@media only screen and (max-width: 767.98px) {
  .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
} 
/*-----------------
	2. Footer
-----------------------*/
.custom-footer {
  background-color: #15558d; 
  color: white; 
}
.footer-title{
  font-weight: bolder;
}
footer a{
color:white;
}

/*-----------------
	3. BreadCrumb
-----------------------*/
.breadcrumb-bar {
	background-color: #15558d;
	padding: 20px 10px 15px 10px;
}

.breadcrumb-bar .breadcrumb-title {
	color: #fff;
	font-size: 22px;
	font-weight: 700;
	margin: 5px 0 0;
  border-color: #15558d;
}
.breadcrumb-bar {
  height: auto;
}
.breadcrumb-bar .breadcrumb-title {
  font-size: 18px;
}
.page-breadcrumb ol {
	background-color: transparent;
	font-size: 12px;
	margin-bottom: 0;
	padding: 0;
}

.page-breadcrumb ol li a {
	color: #fff;
}

.page-breadcrumb ol li.active {
	color: #fff;
}

.page-breadcrumb .breadcrumb-item+.breadcrumb-item:before {
	color: #fff;
	font-size: 10px;
}