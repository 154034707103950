
.textandlocation{
  padding-left: 10px;

}

.infostarloc{
  align-content: center;
  margin-left: 30px;

}


.checkoutContainer {
  padding: 2rem;
}



.thinBorder {
  border: 0.5px solid #ccc;
  width: 100%;
}


/*booking auccess*/


.Bookingform{
  background-color: #15558d;
 color:white;
 width: 100% ;
 height:100%

 
}

.Bookingbold{

  color: white;
  font-weight: bold;
  margin-left: 2%;
  

}

.Bookingdiv{
  background-color: #f8f9fa ;
 width : 100% ;
 height: 100%;  
}

.success{
  width:60%;
  height: 90%;
  align-content: center;
  background-color:white;
  border-color: rgb(129, 123, 123);
  border-radius: 5px;
}

.ViewAppoinment{
  background-color:rgb(89, 210, 154);
  border: none;
  border-radius: 3px;
  color:white;
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 2%;
  padding-bottom: 2%;
  

}

.done{
 color :rgb(89, 210, 154);
 
}


  /* booking */
/* Ensure the elements within the row are cleared and aligned */
/* Your provided styles go here... */


.row {
padding-left: 20px;
clear: both;
}

/* Doctor Details CSS */
.doctor-details-container {
background-color: white;
border: 1px solid #ededed;
padding: 20px;
margin-bottom: 20px;
float: left;
width: 80%;
}

.doctor-details {
display: flex;
align-items: center;
border: 1px solid #ededed;
background-color: white;
padding: 10px;
margin-bottom: 32px;
}

.Dr-02 {
margin-right: 10px;
width: 100px;
height: 95px;
}

.pt {
color: black;
}

.star {
margin-right: 6px;
}

.location {
color: grey;
}

/* Appointment (Slider and Time Slots) CSS */
.appt-container {
background-color: white;
border: 1px solid #ededed;
padding: 20px;
width: 100%;
float: left; /* to make it appear beside sidebar */
clear: both; /* to ensure it's below doctor details */

}

.weekday {
cursor: pointer;
margin-bottom: 6px;
}

/* Time slots grid layout */
.timing-containeradd {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
  margin: 0 auto;
  justify-content: flex-start;
}
.buttontime {
  height: 50px;
  padding: 0;
  display: inline-block;
  font-weight: 400;
  color: #575a5d;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-color: rgb(232, 232, 232);
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  padding: 0;
}
.btn, .btn-primary{
display: flex;
/*margin-top: 100px;*/
background-color: #15558d;


}

.time-slot {
width : 100%;
height:100% ;

position:relative;
display: flex;
justify-content: flex-start;
align-items: center;

}

/* Slider controls positioning */
.slider-button {
position: absolute; /* this will allow you to position buttons on top of the slider */
top: 50%; /* center vertically */
transform: translateY(-50%); /* shift half of its height up */
z-index: 10; /* make sure it's above slider items */
background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
color: white;
border: none;
padding: 10px;
cursor: pointer;
}

.left-button {
left: 5px; /* 5 pixels from the left edge */
}

.right-button {
right: 5px; /* 5 pixels from the right edge */
}

.slick-slide {
width: 120px;
padding-left: 20px;

}
.weekday p{
text-align: center;
}
.selected-date {

  color: rgb(19, 25, 137); /* Text color */
  border-radius: 50%;
  border-color: black;  
}
.selected-time {
background-color: #42c0fb; 
color: white; 
}
/* view app */
.div-invoice-content {
  padding: 20px;
  background-color: #fff;
  border-radius: 20px;
}

.div-rowview {
  display: flex;

  justify-content: flex-start; 
  align-items: right;
}

.div-col-md {
  flex: 1;
}

.logo-png {
  width: 100%;
  max-width: 200px;
  max-height: fit-content;
  flex: start;
  margin-top: 6px;
}

.p-invoice-details-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end; /* align the details to the right side */
}

.p-invoice-details {
  text-align: right;
}

.text-wrapper {
  font-weight: bold;
}

.div {
  display: flex;
  justify-content: space-between; /* This makes the doctor and patient divs be on opposite sides */
}

.div-invoice-info-wrapper {
  padding: 10px;
}

.div-invoice-info-2 {
  flex: 1;
  padding: 10px;
}
.div-invoice-info, .div-invoice-info-2 {
  padding: 15px;
  border: transparent;
  
}

.strong-doctor-clinic, .strong-patient {
  font-weight: bold;
  margin-bottom: 10px;
}

.px-4 {
  padding-left: 15px;
  padding-right: 15px;
}

.py-2 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.overflow-auto {
  overflow: auto;
}

.heading-other {
  font-weight: bold;
}

@media  screen and (max-width: 1200px) {
  .timing-containeradd {
    grid-template-columns: repeat(5, 1fr); /* Display 4 time slots side by side */
  }
  }
/* Medium Devices, Tablets */
@media  screen and (max-width: 1024px) {
.timing-containeradd {
  grid-template-columns: repeat(4, 1fr); /* Display 4 time slots side by side */
}
}
/* Small Devices, Phones */
@media  screen and (max-width: 576px) {
.timing-containeradd {
  grid-template-columns: repeat(3, 1fr); /* Display 3 time slots side by side */
}
.buttontime {
  padding: .275rem .55rem; /* Reduce padding for buttons on small screens */
  font-size: 0.9rem; /* Slightly smaller font size */
}
}