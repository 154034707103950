.review{
  width: 77%;
  float:right;
  /* border-style: ridge;
  height: 220px; */
  border-radius: 20px;
  border: 2px solid #ccc;
 
}

.reviewimg img{
  width: 80px; 
  height: 80px; 
  border-radius: 50%;
  float: left;
}
.rating{
  font-size: 20px;
  margin-bottom: 39px;
  margin-top: -35px;
  margin-left: 80%;
  float: right;

  
}
.star {
  cursor: pointer;
  color: #ccc; 
  margin: 0.2rem;
}
.star.filled {
  color: #f4c150; 
}
.textreview h3{
  padding-bottom: 20px;
  margin-bottom: 10px;
  margin-bottom: -28px;
}
.textreview p{
  font-size: 15px;
}
.textreview{
  padding-left: 90px;
  margin-bottom: 0px;
}
.likebutton button{
  border-style: none;
  background-color: white;
  font-size: 17px;
  padding-left: 10px;
}
.art p{
  padding-left: 90px;
}
.replybutton button{
  border-style: none;
  background-color: white;
  color:#0d99ff;
  margin-left: 85px;
}
.recommend{
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  float: right;
  margin-top: -30px;
}
.recommend p{
  padding: 10px;
  margin-top: 20px;
}

.custom-content{
margin-left: 90px;
}
.reviewimg2 img{
  width: 80px; 
  height: 80px; 
  border-radius: 50%;
}
.class1{
  width: 77%;
  float:right;
  border-radius: 20px;
  border: 2px solid #ccc;
}
.star {
  cursor: pointer;
  color: #ccc;
  margin: 0.2rem;
}

.my-3 {
  background-color: white;
  margin-bottom: 4rem!important;
  border-radius: 20px;
}
.border{
  margin-top: -14px;
  
}
.profile{
  margin-top: 10px;
}