.socialmedia{
    width: 77%;
    float:right;
  }
   
  
  .text-area{
    text-align: center;
    display: flex;
  }
  
  .link{
    width: 50%;
    border-radius: 5px;
    font-size: 25px;
    border-style: solid;
  }
  
  .saveurl{
    text-align: center;
    margin-top: 20px;
    color: white;
    background-color: #15558d;
    border-style: none;
    border-radius: 6px;
    font-size: 20px;
    position: absolute;
    padding: 20px;
    line-height: 8px;
  }
  